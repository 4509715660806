/* NAV BAR */
.header-banner {
  font-family: var(--font-family-rasa);
  color: var(--white);
  line-height: normal;
}

.banner-text {
  display: block;
}

.equal-housing-container {
  align-items: center;
  display: flex;
  padding-left: 0;
  margin-left: 0 !important;
  min-height: 21px;
  gap: 5px;
  color: inherit;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
  cursor: none;
  & img {
    margin-left: 0;
  }
}

.equal-housing-logo {
  max-height: 21px;
  max-width: 19px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  text-align: left;
  margin: 1rem 0 0 1rem
}

footer  {
  & .signature {
    margin-bottom: 1rem;
  }

  & > * {
    margin-left: 1rem;
  }
}
.dropdown-icon {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.selectedLink {
  background-color: lightgray;
}