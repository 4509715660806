.stock-img-container {
  width: 100%;
  &-home {
    aspect-ratio: 3 / 1.09;
  }
  &-about {
    aspect-ratio: 5 / 2.28;
  }
  &-contact {
    aspect-ratio: 3 / 1.09;
  }
}

.home-stock-img {
  width: 100%;
  height: 100%;
}
.image-container {
  position: relative;
}

.content-text{
  &-home {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 34px;
    @media screen and (max-width: 850px) {
      font-size: 22px;
    }
    @media screen and (max-width: 550px) {
      font-size: 16px;
    }
  }
  &-about-us {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 24px;
    height: 800px;
    margin-left: 3.5rem;
    & p {
      max-width: 40%;
    }
    @media screen and (max-width: 850px) {
      font-size: 18px;
      flex-wrap: wrap;
      height: fit-content;
      align-items: center;
      margin-left: 0;
      & p {
        max-width: 80%;
      }
    }
    @media screen and (max-width: 550px) {
      font-size: 16px;
      flex-wrap: wrap;
      height: fit-content;
      align-items: center;
      margin-left: 0;
      & p {
        max-width: 80%;
      }
    }
  }
}

.news {
  padding: 0;

  & :last-child {
    border: none;
  }
  & .view-button {
    color: var(--eastern-blue);
    margin-bottom: 1rem;
    text-decoration: underline;
  }

  & .logo {
    &-nerdwallet {
      width: 180px;
      height: 29px;
    }
    &-forbes {
      width: 130px;
      height: 51px;
    }
    &-time {
      width: 138px;
      height: 42px;
    }
    &-americanbanker {
      width: 220px;
      height: 32px;
    }
    &-techcrunch {
      width: 232px;
      height: 40px;
    }
    &-thestreet {
      width: 161px;
      height: 37px;
    }
    &-nasdaq {
      width: 188px;
      height: 53px;
    }
    &-lendacademy {
      width: 283px;
      height: 49px;
    }
    &-crowdfund {
      width: 213px;
      height: 43px;
    }
    &-alleywatch {
      width: 213px;
      height: 55px;
    }
  }

}

.info-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3rem;
  @media screen and (min-width: 551px) and (max-width: 1171px) {
    width: 100vw !important;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 16px;
    margin: 0;
    padding: 0 3rem 0 3rem;
  }
  @media screen and (max-width: 550px) {
    flex-wrap: nowrap;
    font-size: 16px;
    margin: 0;
    padding: 0 2rem 0 2rem;
  }
}

.info-link-content {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 0 !important;
  margin-bottom: 4rem;
  width: 90%;

  & > * {
    margin: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  & > :last-child {
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 551px) and (max-width: 1171px) {
    font-size: 24px;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 550px) {
    font-size: 16px;
    align-items: center;
    width: 100%;
  }

}

.page-title {
  margin-bottom: 0;
  align-self: flex-start;
}

.ccpa-rights2-list {
  list-style-type: decimal;
}

.news-page{
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  
  @media screen and (max-width: 550px){
    padding-left: 2rem;
  }
}