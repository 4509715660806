@import url("https://fonts.googleapis.com/css?family=Rasa:400,700|Pragati+Narrow:400|Quando:400");
@import "./styles/template.scss";
@import "./styles/pages.scss";

:root {
  --black: rgba(0, 0, 0, 1);
  --blumine: rgba(26, 102, 129, 1);
  --eastern-blue: rgba(40, 125, 159, 1);
  --powder-blue: rgba(178, 223, 239, 1);
  --mystic: rgba(224, 231, 237, 1);
  --white: rgba(255, 255, 255, 1);

  --font-size-xxxs: 12px;
  --font-size-xxs: 14px;
  --font-size-xs: 18px;
  --font-size-s: 20px;
  --font-size-m: 24px;
  --font-size-l: 36px;
  --font-size-xl: 48px;

  --font-family-pragati_narrow: "Pragati Narrow";
  --font-family-quando: "Quando";
  --font-family-rasa: "Rasa";
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-x: hidden;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}